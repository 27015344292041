// src/components/Chat.tsx
import React, { useState } from 'react';
import { Box, TextField, Button } from '@mui/material';
import Message from './Message';

const Chat: React.FC = () => {
  const [messages, setMessages] = useState<{ text: string; isUser: boolean }[]>([]);
  const [input, setInput] = useState('');

  const handleSendMessage = () => {
    if (input.trim()) {
      setMessages([...messages, { text: input, isUser: true }]);
      setTimeout(() => {
        setMessages((prev) => [...prev, { text: "This is a simulated response.", isUser: false }]);
      }, 1000);
      setInput('');
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };
  return (
    <Box sx={{ p: 2, maxWidth: 600, margin: 'auto' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '70vh',
          border: '1px solid #ddd',
          borderRadius: 4,
          padding: 2,
          overflowY: 'auto',
          mb: 2,
        }}
      >
        {messages.map((msg, index) => (
          <Message key={index} text={msg.text} isUser={msg.isUser} />
        ))}
      </Box>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <TextField
          fullWidth
          variant="outlined"
          value={input}
          onKeyPress={handleKeyPress}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type your message..."
        />
        <Button variant="contained" color="primary" onClick={handleSendMessage}>
          Send
        </Button>
      </Box>
    </Box>
  );
};

export default Chat;
