import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3ECEB3',
      contrastText: '#FFFFFF',
    },
    background: {
        default: '#3ECEB31A', // 10% opacity of the primary color
      },
  },
});

export default theme;