import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Box, Container } from '@mui/material';
import Chat from './components/Chat';

function App() {
  return (
    <Box sx={{backgroundColor:"background.default", minHeight: '100vh'}}>
    <Container >
      <Chat/>
    </Container>
    </Box>
        
  );
}

export default App;
