// src/components/Message.tsx
import React from 'react';
import { Box, Typography } from '@mui/material';

interface MessageProps {
  text: string;
  isUser: boolean;
}

const Message: React.FC<MessageProps> = ({ text, isUser }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: isUser ? 'flex-end' : 'flex-start',
        mb: 2,
      }}
    >
      <Box
        sx={{
          maxWidth: '70%',
          padding: '8px 16px',
          borderRadius: 4,
          backgroundColor: isUser ? '#1976d2' : '#f1f1f1',
          color: isUser ? '#fff' : '#000',
        }}
      >
        <Typography variant="body1">{text}</Typography>
      </Box>
    </Box>
  );
};

export default Message;
